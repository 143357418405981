import { makeObservable, configure, observable, action } from 'mobx'
import { axiosPublic } from '../modules/axios/axiosPublic'
import { axiosPrivate } from '../modules/axios/axiosPrivate'
import { dataFilter, filterParams } from '../constants'

configure({ enforceActions: 'observed' })

class Filter {
  rootStore = null

  dataFilter = dataFilter

  filterParams = filterParams

  currentFilter = {}

  modalWindow = false

  constructor(rootStore) {
    this.rootStore = rootStore

    makeObservable(this, {
      currentFilter: observable,
      modalWindow: observable,
      dataFilter: observable,
      filterParams: observable,
      setBks: action,
      setCurrentFilter: action,
      updateCurrentFilter: action,
      actualizeDataFilter: action,
      changeProfit: action,
      changeIsFraction: action,
      changeShowAsian: action,
      changeShowMain: action,
      changeShowHandicap: action,
      changeShowTotal: action,
      changeShowIndividualTotal: action,
      changeTimeEvent: action,
      changeBks: action,
      changePlace: action,
      setDisabledLivePlace: action,
      changeActiveSport: action,
      changeActiveFilterSport: action,
      changeDeleteTimeBets: action,
      toggleModalWindow: action,
      setFilterParams: action,
      actualizeFilterParams: action
    })

    this.getBks()

    this.setCurrentFilter()
  }

  async getBks() {
    try {
      const res = await axiosPublic.get('/listBk')
      const data = res.data.data

      const bks = this.currentFilter?.bks || []

      const arr = data.map((item) => {
        bks.length === 0 || bks.includes(item.bkNameSystem)
          ? (item.checked = true)
          : (item.checked = false)
        return item
      })

      this.setBks(arr)
    } catch (e) {
      console.error(e)
    }
  }

  setBks(arr) {
    this.dataFilter.bks.data = arr
    this.dataFilter.bks.value = arr
  }

  async setCurrentFilter(payload = {}) {
    if (Object.keys(payload).length <= 1) {
      const localData = JSON.parse(localStorage.getItem('CurrentFilter'))

      if (localData) {
        payload = localData
      } else {
        const selected = this.dataFilter.bks.value.filter(
          (item) => item.checked
        )
        const all = this.dataFilter.bks.value
        const bks =
          all.length === selected.length
            ? []
            : selected.map((item) => item.bkNameSystem)

        payload = {
          sportName:
            this.dataFilter.sports.value === 'Топ'
              ? []
              : [this.dataFilter.sports.value],
          timeEvent: this.dataFilter.timeEvent.value.value,
          bks: bks,
          place: this.dataFilter.place.value.name,
          profit: this.dataFilter.profit.value,
          count: this.dataFilter.count.value,
          eventId: this.dataFilter.eventId.value,
          isFraction: this.dataFilter.isFraction.value,
          showAsian: this.dataFilter.showAsian.value,
          showMain: this.dataFilter.showMain.value,
          showHandicap: this.dataFilter.showHandicap.value,
          showTotal: this.dataFilter.showTotal.value,
          showIndividualTotal: this.dataFilter.showIndividualTotal.value,
          deleteTimeBets: this.dataFilter.deleteTimeBets.value,
          default: 'default' in payload ? payload.default : true
        }
      }
    }

    if ('showAsian' in payload) {
      if (payload.showAsian === null)
        this.currentFilter.showAsian = this.dataFilter.showAsian.value
    }
    if ('showMain' in payload) {
      if (payload.showMain === null)
        this.currentFilter.showMain = this.dataFilter.showMain.value
    }
    if ('showHandicap' in payload) {
      if (payload.showHandicap === null)
        this.currentFilter.showHandicap = this.dataFilter.showHandicap.value
    }
    if ('showTotal' in payload) {
      if (payload.showTotal === null)
        this.currentFilter.showTotal = this.dataFilter.showTotal.value
    }
    if ('showIndividualTotal' in payload) {
      if (payload.showIndividualTotal === null)
        this.currentFilter.showIndividualTotal =
          this.dataFilter.showIndividualTotal.value
    }

    this.currentFilter = payload

    this.actualizeDataFilter(payload)
    this.actualizeFilterParams()
  }

  updateCurrentFilter(array) {
    for (let row of array) {
      const { name, value } = row
      this.currentFilter[name] = value
    }

    this.saveFilter()

    this.rootStore.SportEvent.getEventsFromServer(this.currentFilter)

    this.actualizeFilterParams()
    this.rootStore.SportEvent.getStatistics()
  }

  async saveFilter() {
    try {
      const res = await axiosPrivate.post(
        '/auth/saveUserFilters',
        this.currentFilter
      )

      const { error, message } = res.data
      if (error) console.error(message)
    } catch (e) {
      console.error(e)
    }
  }

  actualizeDataFilter(payload) {
    if (!this.rootStore.Auth.authUser) return

    if ('sportName' in payload) {
      const sports = payload.sportName

      this.setDisabledLivePlace(false)

      if (sports.length === 0 || sports.length > 1) {
        this.dataFilter.sports.value = 'Топ'

        this.setDisabledLivePlace(true)

        if (sports.length > 1) {
          this.dataFilter.sports.data = this.dataFilter.sports.data.map(
            (item) => {
              sports.includes(item.filter)
                ? (item.active = true)
                : (item.active = false)
              return item
            }
          )
        }
      } else if (sports.length === 1) {
        this.dataFilter.sports.value = payload.sportName[0]
      }
    }
    if ('place' in payload) {
      const currentPlace = this.dataFilter.place.data.find(
        (item) => item.name === payload.place
      )
      if (currentPlace) {
        this.dataFilter.place.value = currentPlace

        this.rootStore.SportEvent.changeTypeBets(currentPlace.name === 'line')
      }
    }
    if ('profit' in payload) {
      const place = this.dataFilter.place.value.name
      const finded = this.dataFilter.profit.data.find(
        (item) => item.name === place
      )
      if (finded) {
        finded.value = payload.profit
      } else {
        this.dataFilter.profit.data.push({
          name: place,
          value: payload.profit
        })
      }
      this.dataFilter.profit.value = payload.profit
    }
    if ('bks' in payload) {
      if (payload.bks.length > 0) {
        this.dataFilter.bks.value = this.dataFilter.bks.value.map((item) => {
          payload.bks.includes(item.bkNameSystem)
            ? (item.checked = true)
            : (item.checked = false)
          return item
        })
      }
    }
    if ('isFraction' in payload) {
      this.dataFilter.isFraction.value = payload.isFraction
    }
    if ('showAsian' in payload) {
      this.dataFilter.showAsian.value = payload.showAsian
    }
    if ('showMain' in payload) {
      this.dataFilter.showMain.value = payload.showMain
    }
    if ('showHandicap' in payload) {
      this.dataFilter.showHandicap.value = payload.showHandicap
    }
    if ('showTotal' in payload) {
      this.dataFilter.showTotal.value = payload.showTotal
    }
    if ('showIndividualTotal' in payload) {
      this.dataFilter.showIndividualTotal.value = payload.showIndividualTotal
    }
    if ('timeEvent' in payload) {
      const currentTimeEvent = this.dataFilter.timeEvent.data.find(
        (item) => item.value === payload.timeEvent
      )
      if (currentTimeEvent) this.dataFilter.timeEvent.value = currentTimeEvent
    }
  }

  toggleModalWindow(status) {
    if (status !== undefined) {
      this.modalWindow = status
    } else {
      this.modalWindow = !this.modalWindow
    }
  }

  // Обработчики, меняющие значения фильтра
  changeProfit(value) {
    value.length > 0 ? (value = Number(value)) : (value = 0)

    const place = this.dataFilter.place.value.name
    this.dataFilter.profit.value = value

    const finded = this.dataFilter.profit.data.find(
      (item) => item.name === place
    )
    if (finded) {
      finded.value = value
    } else {
      this.dataFilter.profit.data.push({ name: place, value })
    }

    this.updateCurrentFilter([{ name: 'profit', value }])
  }

  changeIsFraction(value) {
    this.dataFilter.isFraction.value = value
    this.updateCurrentFilter([{ name: 'isFraction', value }])
  }

  changeShowAsian(value) {
    this.dataFilter.showAsian.value = value
    this.updateCurrentFilter([{ name: 'showAsian', value }])
  }

  changeShowMain(value) {
    this.dataFilter.showMain.value = value
    this.updateCurrentFilter([{ name: 'showMain', value }])
  }

  changeShowHandicap(value) {
    this.dataFilter.showHandicap.value = value
    this.updateCurrentFilter([{ name: 'showHandicap', value }])
  }

  changeShowTotal(value) {
    this.dataFilter.showTotal.value = value
    this.updateCurrentFilter([{ name: 'showTotal', value }])
  }

  changeShowIndividualTotal(value) {
    this.dataFilter.showIndividualTotal.value = value
    this.updateCurrentFilter([{ name: 'showIndividualTotal', value }])
  }

  changeTimeEvent(value) {
    const finded = this.dataFilter.timeEvent.data.find(
      (item) => item.value === value
    )
    if (finded) {
      this.dataFilter.timeEvent.value = finded

      this.updateCurrentFilter([{ name: 'timeEvent', value }])
    }
  }

  changeBks(value) {
    const finded = this.dataFilter.bks.value.find((item) => item.id === value)
    if (finded) {
      finded.checked = !finded.checked

      const selected = this.dataFilter.bks.value.filter((item) => item.checked)
      const all = this.dataFilter.bks.value
      const values =
        all.length === selected.length
          ? []
          : selected.map((item) => item.bkNameSystem)

      this.updateCurrentFilter([{ name: 'bks', value: values }])
    }
  }

  changePlace(value) {
    const finded = this.dataFilter.place.data.find(
      (item) => item.label === value
    )
    if (finded) {
      this.dataFilter.place.value = finded

      // От типа линии зависит профит
      const place = finded.name
      const findedProfit = this.dataFilter.profit.data.find(
        (item) => item.name === place
      )

      if (findedProfit) {
        this.dataFilter.profit.value = findedProfit.value
      } else {
        this.dataFilter.profit.value = 0
        this.dataFilter.profit.data.push({ name: place, value: 0 })
      }

      const profit = findedProfit ? findedProfit.value : 0

      this.updateCurrentFilter([
        { name: 'place', value: finded.name },
        { name: 'profit', value: profit }
      ])
    }
  }

  setDisabledLivePlace(disabled = false) {
    const finded = this.dataFilter.place.data.find(
      (item) => item.name === 'live'
    )
    if (finded) {
      finded.disabled = disabled
    }
  }

  changeActiveSport(value) {
    const currentSport = this.dataFilter.sports.data.find(
      (item) => item.label === value
    )

    this.dataFilter.sports.value = currentSport.filter

    const values = value === 'Топ' ? [] : [currentSport.filter]

    if (value === 'Топ') {
      this.setDisabledLivePlace(true)
    } else {
      this.setDisabledLivePlace(false)
    }

    if (value === 'Топ') {
      this.dataFilter.sports.data.map((item) => item.active = true)
    }

    this.updateCurrentFilter([{ name: 'sportName', value: values }])
  }

  changeActiveFilterSport(value) {
    const finded = this.dataFilter.sports.data.find(
      (item) => item.label === value
    )
    if (finded) {
      finded.active = !finded.active

      const values = this.dataFilter.sports.data
        .filter((item) => item.active)
        .map((item) => item.filter)

      this.updateCurrentFilter([{ name: 'sportName', value: values }])
    }
  }

  changeDeleteTimeBets(value) {
    if (!value) value = 0
    this.dataFilter.deleteTimeBets.value = value
    this.updateCurrentFilter([{ name: 'deleteTimeBets', value }])
  }

  setFilterParams(params = []) {
    for (let { name, value, additional } of params) {
      const finded = this.filterParams.find((item) => item.name === name)
      if (finded) {
        finded.value = value
        finded.additional = additional
      }
    }
  }

  actualizeFilterParams() {
    const countBks = this.dataFilter.bks.value.filter(
      (item) => item.checked
    ).length
    const countAllBks = this.dataFilter.bks.value.length

    const countAllSports =
      this.dataFilter.sports.value === 'Топ'
        ? this.dataFilter.sports.data.length - 1
        : 1 // -1 так как TOP
    let countSports =
      this.dataFilter.sports.value === 'Топ'
        ? this.dataFilter.sports.data.filter((item) => item.active).length
        : 1
    if (countSports === 0) countSports = countAllSports // Пустой массив - все виды спорта

    const startEvents = this.dataFilter.timeEvent.value.label
    const typeBets = this.dataFilter.isFraction.value ? 'Только дробные' : 'Все'
    const deleteTimeBets = this.dataFilter.deleteTimeBets.value

    const arr = [
      { name: 'bookmakers', value: `${countBks}/${countAllBks}` },
      { name: 'sports', value: `${countSports}/${countAllSports}` },
      { name: 'timeEvents', value: startEvents },
      { name: 'typeValues', value: typeBets },
      { name: 'deleteTimeBets', value: deleteTimeBets }
    ]

    this.setFilterParams(arr)
  }
}

export default Filter
