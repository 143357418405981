import { BackDrop, ModalWindow } from '../ModalWindowComponents'
import { useApp } from '../../../../hook/useApp'
import { observer } from 'mobx-react-lite'
import styles from '../../../autorization/autorization.module.css'
import { useFormik } from 'formik'
import { classNames } from 'primereact/utils'
import { Captcha } from 'primereact/captcha'

export const ModalWriteToUs = observer(() => {
  const { store } = useApp()

  const title = store.UIInterface.modalWriteToUs.title
  const visible = store.UIInterface.modalWriteToUs.visible
  const responseMessage = store.UIInterface.modalWriteToUs.responseMessage
  const statusModal = store.UIInterface.modalWriteToUs.status

  function handlerClose(e) {
    const clickClass = e.target.className

    if (!clickClass.includes('modal-window__close'))
      return false

    store.UIInterface.changeStateModalWriteToUs({
      type: 'visible',
      value: false
    })

    formik.resetForm()
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
      captcha: ''
    },
    validate: (data) => {
      let errors = {}

      if (!data.name) {
        errors.name = 'Имя обязательно для заполнения'
      }

      if (!data.email) {
        errors.email = 'E-mail обязателен для заполнения'
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = 'Некорректный e-mail. Пример: example@email.com'
      }

      if (!data.message) {
        errors.message = 'Необходимо написать текст обращения'
      }

      if (!data.captcha) {
        errors.captcha = 'Необходимо пройти капчу'
      }

      return errors
    },
    onSubmit: async (data) => {
      const status = await store.UIInterface.sendWriteToUsMessage(data)
      if (status) formik.resetForm()
    }
  })

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name])
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    )
  }

  function showResponse(response) {
    formik.setFieldValue('captcha', response)
  }

  function resetCaptcha() {
    formik.setFieldValue('captcha', '')
  }

  if (!visible) return ''
  if (statusModal) {
    return (
      <BackDrop>
        <ModalWindow
          title={'Сообщение отправлено'}
          handlerClose={handlerClose}
          size="middle"
        >
          <div className={styles['modal-window_row']}>
            <p>
              Благодарим за обращение. Оно будет рассмотрено в ближайшее время.
              Спасибо, что пользуетесь нашим сервисом
            </p>
          </div>
        </ModalWindow>
      </BackDrop>
    )
  }
  return (
    <BackDrop>
      <ModalWindow title={title} handlerClose={handlerClose} size="middle">
        <form onSubmit={formik.handleSubmit}>
          <div className={styles['modal-window_row']}>
            <input
              type="text"
              placeholder="Имя"
              className={classNames({ 'p-error': isFormFieldValid('name') })}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {getFormErrorMessage('name')}
          </div>
          <div className={styles['modal-window_row']}>
            <input
              type="text"
              placeholder="E-mail"
              className={classNames({ 'p-error': isFormFieldValid('email') })}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            {getFormErrorMessage('email')}
          </div>
          <div className={styles['modal-window_row']}>
            <textarea
              placeholder="Введите сообщение"
              className={classNames({ 'p-error': isFormFieldValid('message') })}
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
            />
            {getFormErrorMessage('message')}
          </div>
          <div
            className={
              styles['modal-window_row'] +
              ' ' +
              styles['modal-window_row--center']
            }
          >
            <Captcha
              siteKey={process.env.REACT_APP_CAPTCHA_KEY}
              onResponse={showResponse}
              onExpire={resetCaptcha}
              language="ru"
            />
            {getFormErrorMessage('captcha')}
          </div>
          <div
            className={
              styles['modal-window_row'] +
              ' ' +
              styles['modal-window_row--center']
            }
          >
            <div className={styles['modal-window_col']}>
              {responseMessage}
              <button type="submit" className="app-button">
                Написать
              </button>
            </div>
          </div>
        </form>
      </ModalWindow>
    </BackDrop>
  )
})
