import axios from 'axios'
import { memoizedRefreshToken } from './refreshToken'
import toast from 'react-hot-toast'

const baseURL = process.env.REACT_APP_BACKEND_URL

axios.defaults.baseURL = baseURL

axios.interceptors.request.use(
  async (config) => {
    const session = JSON.parse(localStorage.getItem('session'))

    if (session?.accessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${session?.accessToken}`
      }
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true

      const result = await memoizedRefreshToken()

      if (result?.accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.accessToken}`
        }
      }

      return axios(config)
    }

    if (error?.response?.status === 400) {
      toast.error(`${error?.response?.status} некорректный запрос`, {
        duration: 5000,
      });
    }

    if (error?.response?.status === 401) {
      toast.error(`${error?.response?.status} не авторизован`, {
        duration: 5000,
      });
    }

    if (error?.response?.status === 403) {
      toast.error(`${error?.response?.status} нет доступа`, {
        duration: 5000,
      });
    }

    if (error?.response?.status === 404) {
      toast.error(`${error?.response?.status} не найден`, {
        duration: 5000,
      });
    }

    return Promise.reject(error)
  }
)

export const axiosPrivate = axios
