import mem from 'mem'
import { axiosPublic } from './axiosPublic'
import jwt from 'jwt-decode'

const refreshTokenFn = async () => {
  try {
    const session = JSON.parse(localStorage.getItem('session'))

    const response = await axiosPublic.post('/auth/refresh', {
      refreshToken: session?.refreshToken
    })

    const { data } = response.data

    if (!data?.accessToken) {
      localStorage.removeItem('session')
      localStorage.removeItem('user')
    } else {
      const userInfo = jwt(data.accessToken)
      localStorage.setItem('user', JSON.stringify(userInfo))
    }

    localStorage.setItem('session', JSON.stringify(data))

    return data
  } catch (error) {
    console.error('ОШИБКА ПРИ ЗАПРОСЕ REFRESH TOKEN...')
  }
}

const maxAge = 10000

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge
})
