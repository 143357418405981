import React from 'react'
import { createRoot } from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import './styles/normalize.css'
import './styles/styles.css'
import App from './App'
import { Notification } from './components/notification/Notification'
import reportWebVitals from './reportWebVitals'

import { BrowserRouter } from 'react-router-dom'
import { AppProvider } from './hoc/AppProvider'
import { ScrollTop } from './components/scroll/ScrollTop'
import { ModalWriteToUs } from './components/layout/modalWindow/WriteToUs/ModalWriteToUs'

import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

// if (process.env.NODE_ENV === 'production') {
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
// }

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <ScrollTop />
        <App />
        <Notification position="top-center" />
        <ModalWriteToUs />
      </AppProvider>
    </BrowserRouter>
    <Toaster position="top-right" reverseOrder={false} />
  </React.StrictMode>
)

reportWebVitals()
