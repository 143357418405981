export const ModalWindow = ({
  children,
  title = 'Фильтр',
  handlerClose,
  size
}) => {
  const sizes = {
    middle: 'modal-window--size-middle'
  }

  return (
    <div className={`modal-window${size in sizes ? ` ${sizes[size]}` : ''}`}>
      <div className={`modal-window__title`}>{title}</div>
      <div className="modal-window__close" onClick={handlerClose}></div>
      {children}
    </div>
  )
}

export const BackDrop = ({ children }) => {
  return (
    <div className="backdrop">
      {children}
    </div>
  )
}
