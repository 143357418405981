import { HomeContent } from '../components/pages/home/HomeContent'
import { ListForks } from '../components/pages/home/forks/Forks'
import EventTitle from '../components/event/Title'

import { Tooltip } from 'primereact/tooltip'

import { useParams } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { useApp } from '../hook/useApp'
import { observer } from 'mobx-react-lite'

const Event = observer(() => {
  const { store } = useApp()
  const { id, place } = useParams()
  const tooltip = useRef(null)

  const tooltipDelay = store.UIInterface.tooltipDelay
  const isMobile = store.UIInterface.is800px

  useEffect(() => {
    window.scrollTo(0, 0)

    const uploadFilter = new Promise(async (resolve, reject) => {
      try {
        await store.Auth.getInfoForUser()
        resolve()
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })

    uploadFilter.then(async () => {
      store.SportEvent.saveEventId(id)
      store.SportEvent.setInfoForEvent()
      store.SportEvent.changeStateShowButtonForks(false)
      await store.SportEvent.getForksForEventFromServer({ id, place })
    })
  }, [id])

  useEffect(() => {
    if (!isMobile) {
      tooltip.current.updateTargetEvents()
    }
  }, [
    store.SportEvent.stateChangeArray,
    store.UIInterface.typeTimeSport,
    store.UIInterface.isProUser
  ])

  useEffect(() => {
    tooltip.current.unloadTargetEvents()
  }, [])

  return (
    <>
      <EventTitle store={store} />
      <HomeContent>
        <div className="container feed-section__container">
          <ListForks />
        </div>
      </HomeContent>
      <Tooltip
        ref={tooltip}
        target=".tooltip-show"
        position="top"
        showDelay={tooltipDelay}
      />
    </>
  )
})

export { Event }
