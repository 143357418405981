import styles from './autorization.module.css'

const Backdrop = ({ children, visible }) => {
  if (!visible) return ''
  return (
    <div className={styles.backdrop}>
      {children}
    </div>
  )
}

export { Backdrop }
